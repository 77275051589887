<template>
  <div>
    <div v-if="loading" class="loading-container pt-12">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </div>
    <v-form
      @submit.prevent="save"
      ref="form"
      v-show="!loading"
      :disabled="$attrs.disabled"
    >
      <ErrorMessages :messages="errors"></ErrorMessages>
      <v-row class="max-width">
        <v-col>
          <v-select
            label="Empresa"
            :items="branches"
            v-model="form.organization_branch_id"
            item-value="id"
            item-text="name"
            :rules="validate('organization_branch_id')"
            required
            dense
            outlined
          ></v-select>
        </v-col>
        <v-col>
          <v-select
            label="Médico"
            :items="doctors"
            v-model="form.doctor_id"
            :rules="validate('doctor_id')"
            required
            dense
            outlined
          ></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-tabs class="nj-tabs" v-model="tab">
            <v-tab>Corpo</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" eager>
            <v-tab-item class="py-4" eager>
              <nj-editor
                :disabled="$attrs.disabled"
                ref="bodyEditor"
                :custom-events="customEditorEvents"
                v-model="form.body"
              >
              </nj-editor>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>

      <slot></slot>
    </v-form>
  </div>
</template>
<style scoped>
.max-width {
  max-width: 600px;
}
</style>
<script>
import Api from '@/resources/NajaSocialApi'
import EditorUpload from '@/resources/editorUpload'
import ErrorMessages from '@/components/ErrorMessages'

export default {
  name: 'DoctorSignatureForm',
  components: { ErrorMessages },
  data: () => ({
    saveLoading: false,
    loading: false,
    tab: 0,
    errors: [],
    branches: [],
    doctors: [],
    customEditorEvents: {
      'image.beforeUpload': EditorUpload.beforeUpload,
    },
    form: {
      name: null,
      subject: null,
      body: null,
    },
  }),

  mounted() {
    this.fetch(this.$route.params.id)
  },

  methods: {
    validate(field) {
      const errorMsg =
        this.errors[field] == null ? false : this.errors[field].join(',')
      if (errorMsg) {
        return [value => !!value || errorMsg]
      }

      return []
    },

    remove() {
      this.$refs.removeQuestionDialog.open({
        onConfirm: () => {
          this.$refs.removeQuestionDialog.toggleLoading()
          Api.roles
            .destroy(this.$route.params.id)
            .then(async () => {
              this.$router.push({ name: 'TaskSchedulers' })
            })
            .catch(error => {
              if (error.response) {
                this.$toast.show(
                  'Erro ao remover Agendamento de Tarefas',
                  'error'
                )
                // this._setErrors(error.response.data.errors);
              } else {
                throw error
              }
            })
            .finally(() => {
              this.$refs.removeQuestionDialog.close()
            })
        },
      })
    },

    _parseForm(data) {
      const formPayload = {
        id: data.id,
        doctor_id: data.doctor_id,
        organization_branch_id: data.organization_branch_id,
        body: data.body,
      }

      this.form = { ...this.form, ...formPayload }
    },

    async fetch(id = null) {
      try {
        this.loading = true
        const branchesResult = await Api.branches()
        this.branches = branchesResult.data.branches

        const doctorsResult = await Api.organization.doctors()
        this.doctors = doctorsResult.data.doctors.map(doctor => {
          return { value: doctor.id, text: doctor.name }
        })

        if (id === null) {
          return
        }

        const doctorSignature = await Api.doctorSignatures.fetch(id)
        this._parseForm(doctorSignature.data.doctor_signature)
      } catch (e) {
        this.$root.$children[0].toast('Erro ao buscar Assinatura do Médico')
        throw e
      } finally {
        this.$scrollTop()
        this.loading = false
      }
    },

    save() {
      this.saveLoading = true

      Api.doctorSignatures
        .save(this.$route.params.id, {
          medical_report_template_doctor_signature: this.form,
        })
        .then(response => {
          this._parseForm(response.data.doctor_signature)
          this.$root.$children[0].toast(
            'Assinatura do Médico salva com sucesso'
          )

          this.$router.push({
            name: 'DoctorSignatureShow',
            params: { id: this.form.id },
          })
        })
        .catch(error => {
          if (error.response) {
            this.$root.$children[0].toast('Erro ao salvar Assinatura do Médico')
            if (error.response.status != 500) {
              this.errors = error.response.data.errors

              this.$refs.form.validate()
            }
          } else {
            throw error
          }

          // throw error;
        })
        .finally(() => {
          this.$scrollTop()
          this.saveLoading = false
        })
    },
  },
}
</script>
